import {
  type BlockComponent,
  useEntitySubgraph,
} from "@blockprotocol/graph/react";

import { RootEntity, RootEntityLinkedEntities } from "./types.gen";
import { Image } from "./components/image";
import { GenerateImage } from "./components/generate-image";
import { RemoteFileEntity } from "@blockprotocol/graph";

export const descriptionKey: keyof RemoteFileEntity["properties"] =
  "https://blockprotocol.org/@blockprotocol/types/property-type/description/";
export const urlKey: keyof RemoteFileEntity["properties"] =
  "https://blockprotocol.org/@blockprotocol/types/property-type/file-url/";

export const App: BlockComponent<RootEntity> = ({
  graph: { blockEntitySubgraph, readonly },
}) => {
  const { rootEntity: blockEntity, linkedEntities } = useEntitySubgraph<
    RootEntity,
    RootEntityLinkedEntities
  >(blockEntitySubgraph);

  const fileEntity = linkedEntities.find(
    ({ linkEntity }) =>
      linkEntity.metadata.entityTypeId ===
      "https://blockprotocol-87igvkbkw.stage.hash.ai/@ciaranm/types/entity-type/generatedimage/v/1",
  )?.rightEntity;

  if (readonly && !fileEntity) {
    return null;
  }

  if (fileEntity) {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <Image
          description={
            fileEntity.properties[descriptionKey] ?? "An AI-generated image"
          }
          url={fileEntity.properties[urlKey]}
        />
      </div>
    );
  }

  return <GenerateImage blockEntity={blockEntity} />;
};
